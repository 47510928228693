<template>
  <div style="background: #fff;margin-top: 15px">
    <head-layout  :head-title="$route.meta.title"
                  :head-btn-options="headBtnOptions"
                  @head-save="headSave('save')"
                  @head-save-back="headSave('back')"
                  @head-cancel="headCancel"
    ></head-layout>
    <div class="newZtFormBox">
      <el-form :inline="false" :model="form" label-width="120px" :rules="rules" ref="ruleForm">
        <el-row>
          <el-col  :span="8">
            <el-form-item :label="$t('cip.dc.shunt.field.InterfaceCode')" prop="infCode">
              <el-select  class = 'new-form-input' v-model="form.infCode"  :placeholder="$t('cip.dc.shunt.field.InterfaceCode')">
                <el-option v-for="(item,index) in selDat" :key="index" :label="item.infDesc" :value="item.infCode"></el-option>
              </el-select>
            </el-form-item>

          </el-col>
          <!-- :label="$t('cip.dc.shunt.field.Transcoding')" -->
          <el-col  :span="8"> 
            <el-form-item  prop="infCodeSplit" label="分流编码">
              <el-input   class = 'new-form-input' :placeholder="$t('cip.dc.shunt.field.Transcoding')" v-model="form.infCodeSplit" size="mini" autocomplete="off"></el-input>
            </el-form-item>
          </el-col>
          <el-col  :span="8">
            <el-form-item :label="$t('cip.dc.shunt.field.sfqy')" >
              <el-select  class = 'new-form-input' v-model="form.isEnable"  :placeholder="$t('cip.dc.shunt.field.sfqy')">
                <el-option  :label="$t('cip.dc.shunt.field.yes')" value="Y"></el-option>
                <el-option  :label="$t('cip.dc.shunt.field.no')" value="N"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col  :span="8">
            <el-form-item :label="$t('cip.dc.shunt.field.describe')" prop="infDesc">
              <el-input   type="textarea" class = 'new-form-input' :placeholder="$t('cip.dc.shunt.field.describe')" v-model="form.infDesc"  autocomplete="off"></el-input>
            </el-form-item>
          </el-col>
          <el-col  :span="8">
            <el-form-item :label="$t('cip.dc.shunt.field.zxtj')" prop="conditions">
              <el-input type="textarea" class = 'new-form-input' :placeholder="$t('cip.dc.shunt.field.zxtj')" v-model="form.conditions"  autocomplete="off"></el-input>
            </el-form-item>
          </el-col>

        </el-row>

      </el-form>
    </div>
  </div>
</template>

<script>
import { dataSplitSel, dataSplitUp} from "@/api/dataAcquisition";

export default {
  name: "dataCoonfigAdd",
  data(){
    return {
      headBtnOptions: [{
        label: this.$t('cip.cmn.btn.saveBtn'),
        emit: "head-save",
        type: "button",
        icon: ""
      },
        {
          label: this.$t('cip.cmn.btn.saveBackBtn'),
          emit: "head-save-back",
          type: "button",
          icon: ""
        }, {
          label: this.$t('cip.cmn.btn.celBtn'),
          emit: "head-cancel",
          type: "button",
          icon: ""
        }],
      rules: {
        infCode: [
          { required: true, message:  this.$t('cip.dc.shunt.msg.interfaceCode'), trigger: 'change' }
        ],
        infCodeSplit: [
          { required: true, message: this.$t('cip.dc.shunt.msg.conversionCode'), trigger: 'change' }
        ],
        infDesc: [
          { required: true, message: this.$t('cip.dc.shunt.msg.description'), trigger: 'change' }
        ],
        conditions: [
          { required: true, message: this.$t('cip.dc.shunt.msg.executionConditions'), trigger: 'change' }
        ],
      },
      form:{
        conditions: "",//执行条件
        infCode: "",//接口编码
        infCodeSplit: "",//转换编码
        infDesc: "",//描述
        isEnable: "",//是偶发启用
        id:'',
      },
      formLabelWidth: '120px',
      selDat:[],
    }
  },
  created(){
    if(this.$route.query.id){
      this.form = JSON.parse(localStorage.getItem('shuntEditObj'));
      this.$route.meta.title = this.$t('cip.dc.shunt.msg.diversionEditing')
    }else{
      this.$route.meta.title = this.$t('cip.dc.shunt.msg.diversionAdd')
    }
    this.datSoSelFn();
  },
  methods:{
    datSoSelFn(){
      dataSplitSel().then((res)=>{
        console.log(res.data.data);
        this.selDat = res.data.data;
      })
    },
    headSave(type){
      this.$refs['ruleForm'].validate((valid) => {
        if (valid) {
          if(this.form.id){
            dataSplitUp(this.form).then(()=>{
              this.$message.success(this.$t('cip.dc.shunt.msg.ModifiedSuccessfully'));
              if(type=='back'){
                this.headCancel()
              }
            })
          }else{
            dataSplitUp(this.form).then(()=>{
              this.$message.success(this.$t('cip.dc.shunt.msg.SuccessfullyAdded'));
              if(type=='back'){
                this.headCancel()
              }
            })
          }
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    headCancel() {
      this.$router.$avueRouter.closeTag();
      this.$router.back();
    },

  }
}
</script>

<style scoped>

</style>
